/* @tailwind base; */
@tailwind components;
@tailwind utilities;

.mantine-Overlay-root {
  z-index: 1000;
}

.mantine-Modal-inner {
  z-index: 1001;
  padding-left: 0;
}
